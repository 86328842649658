import { configureStore } from "@reduxjs/toolkit";
import jsonValidateReducer from "./reducer/json/validator/jsonValidateReducer";

const store = configureStore({
  reducer: {
    jsonValidateStore: jsonValidateReducer,
  },
});

export default store;
