import React, { useState, useEffect, useCallback } from "react";
import AceEditor from "react-ace";

// Import themes and modes
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";

const JsonTextEditor = ({ jsonInput = "", onChange, config = {} }) => {
  const [content, setContent] = useState(jsonInput || "");
  const [mode, setMode] = useState(config.mode || "json");
  const [theme, setTheme] = useState(config.theme || "github");
  const [fontSize, setFontSize] = useState(config.fontSize || 16);
  const [width, setWidth] = useState(config.width || "40%");
  const [height, setHeight] = useState(config.height || "800px");
  const [textColor, setTextColor] = useState(config.textColor || "green");
  const [showGutter, setShowGutter] = useState(config.showGutter ?? true);
  const [showPrintMargin, setShowPrintMargin] = useState(
    config.showPrintMargin ?? false
  );
  const [enableBasicAutocompletion, setEnableBasicAutocompletion] = useState(
    config.enableBasicAutocompletion ?? false
  );
  const [enableLiveAutocompletion, setEnableLiveAutocompletion] = useState(
    config.enableLiveAutocompletion ?? false
  );
  const [enableSnippets, setEnableSnippets] = useState(
    config.enableSnippets ?? false
  );
  const [readOnly, setReadOnly] = useState(config.readOnly ?? false); // Add readOnly state

  // Update content if the `jsonInput` prop changes
  useEffect(() => {
    setContent((prevContent) =>
      prevContent !== jsonInput ? jsonInput : prevContent
    );
  }, [jsonInput]);

  // Dynamically update text color when `config.textColor` changes
  useEffect(() => {
    setTextColor(config.textColor || "green");
  }, [config.textColor]);

  // Handle content changes in the editor
  const handleEditorChange = useCallback(
    (newContent) => {
      setContent(newContent);
      if (onChange) {
        onChange(newContent);
      }
    },
    [onChange]
  );

  return (
    <>
      <AceEditor
        mode={mode}
        theme={theme}
        value={content}
        onChange={handleEditorChange}
        name="json-text-editor"
        fontSize={fontSize}
        width={width}
        height={height}
        showGutter={showGutter}
        showPrintMargin={showPrintMargin}
        highlightActiveLine={false}
        readOnly={readOnly}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion,
          enableLiveAutocompletion,
          enableSnippets,
        }}
        style={{ color: textColor }}
      />
    </>
  );
};

export default React.memo(JsonTextEditor);
