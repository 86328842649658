import React from "react";
import { Link } from "react-router-dom";

const JsonNavHeader = () => {
  const buttonClasses =
    "px-3 py-2 text-xs font-medium text-center text-gray-800 bg-gradient-to-r from-cyan-200 via-cyan-300 to-cyan-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800 rounded-lg";

  // Array of navigation items
  const navItems = [
    { path: "/json-validate", name: "Validate" },
    { path: "/json-format", name: "Formatter" },
    { path: "/json-tree-view", name: "Tree Viewer" },
  ];

  return (
    <header>
      <nav className="bg-gray-100 border-gray-300 px-4 lg:px-6 py-1 dark:bg-teal-700">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <ul className="flex space-x-4">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link to={item.path}>
                  <button type="button" className={buttonClasses}>
                    {item.name}
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default JsonNavHeader;
