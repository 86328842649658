import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JsonFormotButton() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore?.left_screen || { text: "" }
  );

  const dispatch = useDispatch();

  const [tabSpace, setTabSpace] = useState(4); // State for tab space

  const handleTabSpaceChange = (event) => {
    setTabSpace(parseInt(event.target.value, 10)); // Update tab space value
  };

  const handleJsonFormot = () => {
    try {
      const leftJson = JSON.parse(left_screen.text);
      const formattedJson = JSON.stringify(leftJson, null, tabSpace);

      dispatch(
        set_right_text_json_validate_action({
          text: formattedJson, // Set formatted JSON
          isValid: true, // Indicate the JSON is valid
        })
      );

      toast.success("JSON formatted successfully!", { autoClose: 3000 });
    } catch (error) {
      dispatch(
        set_right_text_json_validate_action({
          text: `Invalid JSON: ${error.message}`, // Show error message
          isValid: false, // Indicate the JSON is invalid
        })
      );
      toast.error(`Invalid JSON: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-4">
      {/* Container for dropdown and button */}
      <div className="flex items-center space-x-4">
        <select
          id="tabSpace"
          value={tabSpace}
          onChange={handleTabSpaceChange}
          className="text-gray-900 bg-gradient-to-r bg-lime-200 from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <option value={2} className="p-2">
            2 Tab Space
          </option>
          <option value={4} className="p-2">
            4 Tab Space
          </option>
          <option value={8} className="p-2">
            8 Tab Space
          </option>
        </select>
        <button
          type="button"
          onClick={handleJsonFormot}
          className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Format/Beautify Json
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default JsonFormotButton;
