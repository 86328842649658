import React from 'react';
import PropTypes from 'prop-types';
import JsonView from 'react18-json-view';


const JsonTreeEditor = ({
  src,
  className = '',
  style = {},
  dark = false,
  theme = 'default',
  enableClipboard = true,
  matchesURL = true,
  urlRegExp = /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
  displaySize = false,
  collapseStringsAfterLength = 99,
  customizeCollapseStringUI,
  ignoreLargeArray = false,
  collapseStringMode = 'directly',
  collapsed = false,
  collapseObjectsAfterLength = 99,
  editable = false,
  onAdd,
  onDelete,
  onEdit,
  customizeNode,
  customizeCopy,
  height = '100%',
  width = '100%',
}) => {
  const containerStyle = {
    height,
    width,
    overflow: 'auto',
    border: dark ? '1px solid #444' : '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: dark ? '#2d2d2d' : '#fff',
    boxSizing: 'border-box',
    ...style,
  };

  return (
    <div className={className} style={containerStyle}>
      <JsonView
        src={src}
        theme={theme}
        enableClipboard={enableClipboard}
        matchesURL={matchesURL}
        urlRegExp={urlRegExp}
        displaySize={displaySize}
        collapseStringsAfterLength={collapseStringsAfterLength}
        customizeCollapseStringUI={customizeCollapseStringUI}
        ignoreLargeArray={ignoreLargeArray}
        collapseStringMode={collapseStringMode}
        collapsed={collapsed}
        collapseObjectsAfterLength={collapseObjectsAfterLength}
        editable={editable}
        onAdd={onAdd}
        onDelete={onDelete}
        onEdit={onEdit}
        customizeNode={customizeNode}
        customizeCopy={customizeCopy}
      />
    </div>
  );
};

JsonTreeEditor.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  dark: PropTypes.bool,
  theme: PropTypes.oneOf([
    'default',
    'a11y',
    'github',
    'vscode',
    'atom',
    'winter-is-coming',
  ]),
  enableClipboard: PropTypes.bool,
  matchesURL: PropTypes.bool,
  urlRegExp: PropTypes.instanceOf(RegExp),
  displaySize: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.oneOf(['collapsed', 'expanded']),
  ]),
  collapseStringsAfterLength: PropTypes.number,
  customizeCollapseStringUI: PropTypes.func,
  ignoreLargeArray: PropTypes.bool,
  collapseStringMode: PropTypes.oneOf(['directly', 'word', 'address']),
  collapsed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.func]),
  collapseObjectsAfterLength: PropTypes.number,
  editable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      add: PropTypes.bool,
      edit: PropTypes.bool,
      delete: PropTypes.bool,
    }),
  ]),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  customizeNode: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.shape({
      style: PropTypes.object,
      type: PropTypes.oneOf(['string', 'number', 'boolean', 'object', 'array']),
    }),
  ]),
  customizeCopy: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default JsonTreeEditor;
