import React, { useState, useCallback, useEffect } from "react";
import JsonMonacoEditor from "../general/JsonMonacoEditor";
import JsonHeader from "../validator/JsonHeader";
import { useSelector, useDispatch } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import JsonGraphVisualizer from "../general/JsonGraphVisualizer";

function JsonGraphView() {
  const [leftPanelWidth, setLeftPanelWidth] = useState(400); // Initial left panel width
  const [isResizing, setIsResizing] = useState(false); // Track resizing state

  const left_screen = useSelector(
    (state) => state.jsonValidateStore.left_screen
  );
  const right_screen = useSelector(
    (state) => state.jsonValidateStore.right_screen
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_right_text_json_validate_action({ text: "", isValid: false }));
  }, [dispatch]);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isResizing) return;

      const newLeftWidth = Math.min(
        Math.max(e.clientX, 300), // Minimum width: 300px
        window.innerWidth - 300 // Maximum width: leave 300px for the right panel
      );

      setLeftPanelWidth(newLeftWidth);
    },
    [isResizing]
  );

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return (
    <>
      <JsonHeader />
      <div className="flex h-screen">
        <div
          style={{ width: `${leftPanelWidth}px` }}
          className="flex-shrink-0 border-r border-gray-300"
        >
          <JsonMonacoEditor
            defaultJson={left_screen.text}
            initialHeight={900}
            initialWidth={leftPanelWidth}
          />
        </div>

        <div
          className="w-2 bg-gray-200 cursor-col-resize h-[95%]"
          onMouseDown={handleMouseDown}
        ></div>

        <div
          style={{ width: `calc(100% - ${leftPanelWidth + 2}px)` }}
          className="flex-grow p-4 overflow-auto"
        >
          <JsonGraphVisualizer json={left_screen.text} />
        </div>
      </div>
    </>
  );
}

export default JsonGraphView;
