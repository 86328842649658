import JsonHeader from "../validator/JsonHeader";
import JsonTextEditor from "../general/JsonTextEditor";
import { useSelector, useDispatch } from "react-redux";
import JsonPiller from "../general/JsonPiller";
import JsonTreeViewButton from "./JsonTreeViewButton";
import {
  set_left_text_json_validate_action,
  set_right_text_json_validate_action,
} from "../../../action/json/validator/jsonValidateAction";
import { useEffect } from "react";
import JsonTreeEditor from "../general/JsonTreeEditor";
import "react18-json-view/src/style.css";

function JsonTreeView() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore.left_screen
  );
  const right_screen = useSelector(
    (state) => state.jsonValidateStore.right_screen
  );

  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(set_left_text_json_validate_action({ text: "", isValid: false }));
    dispatch(set_right_text_json_validate_action({ text: "", isValid: false }));
  }, [dispatch]);

  const handleLeftTextChange = (newContent) => {
    dispatch(set_left_text_json_validate_action({ text: newContent }));
  };

  let parsedSrc;
  try {
    parsedSrc =
      typeof right_screen.text === "string"
        ? JSON.parse(right_screen.text || "{}")
        : right_screen.text;
  } catch (error) {
    parsedSrc = right_screen.text;
  }

  return (
    <>
      <JsonHeader />
      <div className="flex flex-row items-stretch h-[800px]">
        <JsonTextEditor
          jsonInput={left_screen.text}
          onChange={handleLeftTextChange}
        />
        <JsonPiller />
        <div className="w-[350px]">
          <JsonTreeViewButton />
        </div>
        <JsonTreeEditor
          src={parsedSrc}
          height={"100%"}
          width={"50%"}
          editable={false}
        />
      </div>
    </>
  );
}

export default JsonTreeView;
