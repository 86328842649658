import JsonHeader from "../validator/JsonHeader";
import JsonTextEditor from "../general/JsonTextEditor";
import { useSelector, useDispatch } from "react-redux";
import JsonPiller from "../general/JsonPiller";
import JsonFormotButton from "./JsonFormotButton";
import {
  set_left_text_json_validate_action,
  set_right_text_json_validate_action,
} from "../../../action/json/validator/jsonValidateAction";
import { useEffect } from "react";

function JsonFormot() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore.left_screen
  );
  const right_screen = useSelector(
    (state) => state.jsonValidateStore.right_screen
  );

  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(set_left_text_json_validate_action({ text: "", isValid: false }));
    dispatch(set_right_text_json_validate_action({ text: "", isValid: false }));
  }, [dispatch]);

  const handleLeftTextChange = (newContent) => {
    dispatch(set_left_text_json_validate_action({ text: newContent }));
  };

  const handleRightTextChange = (newContent) => {
    dispatch(set_right_text_json_validate_action({ text: newContent }));
  };

  return (
    <>
      <JsonHeader />
      <div className="flex flex-row items-stretch h-[800px]">
        <JsonTextEditor
          jsonInput={left_screen.text}
          onChange={handleLeftTextChange}
        />
        <JsonPiller />
        <div className="w-[350px]">
          <JsonFormotButton />
        </div>
        <JsonTextEditor
          jsonInput={right_screen.text}
          onChange={handleRightTextChange}
          config={{
            readOnly: true,
            textColor: right_screen.isValid ? "green" : "red"
          }}
        />
        <JsonPiller />
      </div>
    </>
  );
}

export default JsonFormot;
