import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/dashboard/Header';
import JsonHeader from './components/json/validator/JsonHeader';
import JsonValidate from './components/json/validator/JsonValidate';
import JsonFormot from './components/json/formattor/JsonFormot';
import JsonTreeView from './components/json/tree/JsonTreeView';
import JsonGraphView from './components/json/graph/JsonGraphView';
const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/json" element={<JsonHeader/>} />
          <Route path="/json-validate" element={<JsonValidate/>} />
          <Route path="/json-format" element={<JsonFormot/>} />
          <Route path="/json-tree-view" element={<JsonTreeView />} />
          {/* Not completed till now */}
          {/* <Route path="/json-graph-view" element={<JsonGraphView/>}/> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
