import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JsonValidateButton() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore?.left_screen || { text: "" }
  );

  const dispatch = useDispatch();

  const handleJsonValidation = () => {
    try {
      JSON.parse(left_screen.text);
      dispatch(
        set_right_text_json_validate_action({
          text: "JSON is valid!",
          isValid: true,
        })
      );
      toast.success("JSON is valid!", { autoClose: 3000 });
    } catch (error) {
      dispatch(
        set_right_text_json_validate_action({
          text: `Invalid JSON: ${error.message}`,
          isValid: false,
        })
      );
      toast.error(`Invalid JSON: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-4">
      <button
        type="button"
        onClick={handleJsonValidation}
        className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
      >
        Validate Json
      </button>
      <ToastContainer />
    </div>
  );
}

export default JsonValidateButton;
